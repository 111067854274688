import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=bd2f886c&scoped=true&"
var script = {}
import style0 from "./BaseFooter.vue?vue&type=style&index=0&id=bd2f886c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd2f886c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCol,VFooter,VIcon})
