import Datatable from '@views/main/dummy-import-user/Datatable';

const dummyImportUserRoutes = [
  {
    path: 'dummy-import-user',
    name: 'dummy_import_user',
    component: Datatable,
  },
];

export default dummyImportUserRoutes;
